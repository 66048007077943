(() => {
	const cookieName = "cookie-consent";
	const local = window.localStorage;
	const getLocal = (cookieName) => local.getItem(cookieName);
	const setLocal = (cookieName, value) => {
		local.setItem(cookieName, value);
	};

	const cookieBanner = document.createElement("div");
	const cookieText = document.createElement("p");
	const cookieLink = document.createElement("a");
	const cookieButton = document.createElement("button");

	const createCookieBanner = () => {
		const _btnClass = ["button-primary", "cookie__button"];
		cookieBanner.classList.add("cookie__banner");
		cookieText.classList.add("cookie__text");
		cookieLink.classList.add("cookie__link");
		cookieButton.classList.add(..._btnClass);

		cookieText.innerHTML =
			"This website uses cookies to add session and authorization information once you are authenticated. Without them, authentication would not work. ";
		cookieLink.innerHTML = "Learn&nbsp;more";
		cookieLink.href = "/cookie-policy";
		cookieButton.innerText = "Got it!";

		cookieText.appendChild(cookieLink);
		cookieBanner.appendChild(cookieText);
		cookieBanner.appendChild(cookieButton);
	};
	const injectCookieBanner = () => {
		createCookieBanner();
		document.body.appendChild(cookieBanner);
	};
	const removeCookieBanner = () => {
		document.body.removeChild(cookieBanner);
	};

	if (!getLocal(cookieName)) {
		injectCookieBanner();
		cookieButton.addEventListener("click", () => {
			setLocal(cookieName, true);
			removeCookieBanner();
		});
	}
})();
